import styled, { css } from 'styled-components'

import { Theme } from 'common/UI'
import { ColoredSectionStoryblok } from 'common/types'
import { getImageAttributes } from 'common/utils/content'

import { DynamicBlock } from './DynamicBlock'

type Props = {
  block: ColoredSectionStoryblok
}

export const ColoredSection: React.FC<Props> = ({ block }) => {
  const { image, theme, body } = block

  return (
    <Theme colorTheme={theme || 'light'}>
      <Wrapper image={getImageAttributes(image).src}>
        {(body || []).map((currentBlock) => (
          <DynamicBlock key={currentBlock._uid} block={currentBlock} />
        ))}
      </Wrapper>
    </Theme>
  )
}

const Wrapper = styled.div<{ image?: string | null }>`
  ${({ image }) =>
    image &&
    css`
      background-image: url('${image}');
      background-size: 100% auto;
      background-position: center center;
      background-repeat: no-repeat;
    `}
`
